import { useEffect, useState } from "react";
import {
  Group,
  Text,
  Title,
  Button,
  NumberInput,
  Divider,
  Center,
  SelectItem,
} from "@mantine/core";
import { BiSave } from "react-icons/bi";
import BrandLines from "../../models/brand-lines";
import Product from "../../models/product";
import PillTabs from "../common/PillTabs";
import PaperConfigSection from "./PaperConfigSection";
import CustomBarsTable, { CustomBarItem } from "./CustomBarsTable";
import AluPrices from "../../models/alu-prices";
import PaintPrices from "../../models/paint-prices";

type ConfigAluminumProps = {
  isLoading: boolean;
  brandLines: BrandLines[];
  prices: AluPrices;
  cutLossPerBar: number;
  cutLossPerCut: number;
  canOptimizeCuts: boolean;
  intl: boolean;
  usd?: boolean;
  colors: Product[];
  customAlu: Product[];
  roundCosts: boolean;
  onSubmit: (barLoss: number, cutLoss: number) => void;
  onCustomSave: (
    brand: string,
    id: string,
    prices: PaintPrices,
    type: string
  ) => Promise<any>;
  onPercentageApply: (
    type: string,
    brand: string,
    percentage: number
  ) => Promise<boolean>;
};

const OTHER_CODE = "other";

const ConfigAluminum = (props: ConfigAluminumProps) => {
  const [barLoss, setBarLoss] = useState<number>(props.cutLossPerBar);
  const [cutLoss, setCutLoss] = useState<number>(props.cutLossPerCut);

  const [perKgBrands, setPerKgBrands] = useState<SelectItem[]>([]);
  const [perBarBrands, setPerBarBrands] = useState<SelectItem[]>([]);
  const [perKgBrandTab, setPerKgBrandTab] = useState<string>();
  const [perBarBrandTab, setPerBarBrandTab] = useState<string>();
  const [brandIsPvc, setBrandIsPvc] = useState<boolean>(false);
  const [lines, setLines] = useState<SelectItem[]>([]);
  const [lineTab, setLineTab] = useState<string>("");

  const [perKgItems, setPerKgItems] = useState<CustomBarItem[]>([]);
  const [perBarItems, setPerBarItems] = useState<CustomBarItem[]>([]);

  // const initialized = useRef(false);

  useEffect(() => {
    const perKg: SelectItem[] = props.brandLines
      .filter((bl) => !bl.ppb && !bl.pvc)
      .map((bl) => ({ value: bl.code, label: bl.name }));
    const perBar: SelectItem[] = props.brandLines
      .filter((bl) => bl.ppb)
      .map((bl) => ({ value: bl.code, label: bl.name }));
    setPerKgBrands(perKg);
    setPerBarBrands(perBar);
  }, [props.brandLines]);

  useEffect(() => {
    if (perKgBrands.length === 0) return;
    setPerKgBrandTab(perKgBrands[0].value);
  }, [perKgBrands]);

  useEffect(() => {
    if (!props.brandLines || !props.prices || !perKgBrandTab) return;
    setPerKgItems(
      props.brandLines
        .find((brand) => brand.code === perKgBrandTab)
        .lines.map((line) => ({
          id: line.code,
          name: line.name,
          code: line.name,
          prices: props.prices[perKgBrandTab]?.[line.code],
        }))
    );
  }, [props.brandLines, props.prices, perKgBrandTab]);

  useEffect(() => {
    setPerBarItems(
      props.customAlu
        .filter(
          (alu) =>
            alu.brd === perBarBrandTab &&
            (lineTab === OTHER_CODE ? !alu.lin : alu.lin === lineTab)
        )
        .map((alu) => ({
          id: alu._id,
          name: alu.name,
          prices: alu.prices,
          code: alu.code,
        }))
    );
  }, [props.customAlu, perBarBrandTab, lineTab]);

  const perBarBrandTabChangeHandler = (value: string) => {
    const brand = props.brandLines.find((bl) => bl.code === value);
    const lns = brand?.lines ?? [];
    setLines(
      lns
        .concat({ code: OTHER_CODE, name: "Otros" })
        .map((line) => ({ value: line.code, label: line.name }))
    );
    setLineTab(lns[0].code);
    setPerBarBrandTab(value);
    setBrandIsPvc(brand.pvc || false);
  };

  const saveCustomAluPriceHandler = async (
    id: string,
    prices: PaintPrices,
    type: "line" | "bar"
  ) => {
    return await props.onCustomSave(
      perKgBrandTab || perBarBrandTab,
      id,
      prices,
      type
    );
  };

  const applyPercentageHandler = async (percentage: number) => {
    return await props.onPercentageApply(
      "alu",
      perKgBrandTab || perBarBrandTab,
      percentage
    );
  };

  const submitHandler = () => {
    props.onSubmit(barLoss, cutLoss);
  };

  return (
    <PaperConfigSection
      title="Barras"
      description={"Defina el costo del aluminio o PVC."}
    >
      {perKgBrands.length > 0 && (
        <>
          <Title order={3}>Costos por kilo</Title>

          <PillTabs
            brands={perKgBrands}
            value={perKgBrandTab}
            color="brand.4"
            onChange={(value) => setPerKgBrandTab(value)}
          />
          <CustomBarsTable
            itemType="line"
            items={perKgItems}
            colors={props.colors}
            roundCosts={props.roundCosts}
            pvc={false}
            showPercentageButton={false}
            currentBrandName={
              perKgBrands.find((b) => b.value === perKgBrandTab)?.label
            }
            onCustomSave={saveCustomAluPriceHandler}
          />
        </>
      )}

      {perBarBrands.length > 0 && (
        <>
          <Divider mt="xl" mb="md" size="sm" />

          <Title order={3}>Costos por barra</Title>

          <PillTabs
            brands={perBarBrands}
            value={perBarBrandTab}
            color="brand.4"
            onChange={(value) => perBarBrandTabChangeHandler(value)}
          />
          {lineTab ? (
            <PillTabs
              brands={lines}
              value={lineTab}
              color="brand.2"
              onChange={(value) => setLineTab(value)}
            />
          ) : (
            <Text color="gray" mb="xs">
              Seleccione una marca para ver las líneas correspondientes.
            </Text>
          )}
          <CustomBarsTable
            itemType="bar"
            items={perBarItems}
            colors={props.colors}
            pvc={brandIsPvc}
            roundCosts={props.roundCosts}
            showPercentageButton={true}
            currentBrandName={
              perBarBrands.find((b) => b.value === perBarBrandTab)?.label
            }
            onCustomSave={saveCustomAluPriceHandler}
            onPercentageApply={applyPercentageHandler}
          />
        </>
      )}

      {props.canOptimizeCuts && (
        <>
          <Divider mt="xl" mb="md" size="sm" />

          <Title order={3}>Optimización de Cortes</Title>

          <Center>
            <Group mt="md" spacing={40}>
              <Group noWrap>
                <Text>Despunte de barra total (mm)</Text>
                <NumberInput
                  sx={{ width: "6em" }}
                  min={0}
                  max={99}
                  value={barLoss || 0}
                  onChange={(value) => setBarLoss(value || 0)}
                />
              </Group>
              <Group noWrap>
                <Text>Corte de hoja (mm)</Text>
                <NumberInput
                  sx={{ width: "6em" }}
                  min={0}
                  max={99}
                  value={cutLoss || 0}
                  onChange={(value) => setCutLoss(value || 0)}
                />
              </Group>
            </Group>
          </Center>
        </>
      )}

      <Group mt="xl" position="right">
        <Button
          loading={props.isLoading}
          onClick={submitHandler}
          sx={{ width: "12em" }}
        >
          <BiSave size="20" />
          <Text ml="6px">Guardar</Text>
        </Button>
      </Group>
    </PaperConfigSection>
  );
};

export default ConfigAluminum;

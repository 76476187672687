import { ReactNode, useState } from "react";
import {
  Group,
  List,
  MantineSize,
  PasswordInput,
  Popover,
  Progress,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { FloatingPosition } from "@mantine/core/lib/Floating";
import { useMediaQuery } from "@mantine/hooks";
import { BiError } from "react-icons/bi";

type PasswordEditInputProps = {
  label?: string;
  form?: string;
  size?: MantineSize;
  placeholder?: string;
  icon?: ReactNode;
  value: string;
  required?: boolean;
  disabled?: boolean;
  popoverPosition?: FloatingPosition;
  onChange: (value: string) => void;
  onValidityChange: (valid: boolean) => void;
};

const MIN_PASSWORD_LENGTH = 8;
const MAX_PASSWORD_LENGTH = 64;

const PasswordEditInput = (props: PasswordEditInputProps) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [strengthColor, setStrengthColor] = useState("red");

  const isNarrowScreen = useMediaQuery("(max-width: 1150px)");

  const requirements = [
    { reg: /[0-9]/, label: "Incluir número" },
    { reg: /[a-z]/, label: "Incluir minúsculas" },
    { reg: /[A-Z]/, label: "Incluir mayúsculas" },
  ];

  const valueChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    props.onChange(newValue);

    // Recalculate strength value
    let strength = newValue.length >= MIN_PASSWORD_LENGTH ? 1 : 0;
    requirements.forEach((req) => {
      if (req.reg.test(newValue)) strength++;
    });
    const newStrengthValue = Math.min(
      (strength / (requirements.length + 1)) * 100,
      100
    );
    setPasswordStrength(newStrengthValue);
    props.onValidityChange(newStrengthValue >= 95);

    if (newStrengthValue <= 25) setStrengthColor("red");
    else if (newStrengthValue <= 50) setStrengthColor("orange");
    else if (newStrengthValue <= 75) setStrengthColor("yellow");
    else setStrengthColor("green");
  };

  return (
    <Popover
      opened={openedPopover}
      position={isNarrowScreen ? "top-end" : "right-end"}
      withArrow
      trapFocus={false}
      transitionProps={{ transition: "pop-top-left" }}
      shadow="md"
      width={350}
      withinPortal
    >
      <Popover.Target>
        <PasswordInput
          size={props.size || "md"}
          label={props.label}
          placeholder={props.placeholder}
          icon={props.icon}
          form={props.form}
          value={props.value}
          onFocusCapture={() => setOpenedPopover(true)}
          onBlurCapture={() => setOpenedPopover(false)}
          onChange={valueChangeHandler}
          maxLength={MAX_PASSWORD_LENGTH}
          disabled={props.disabled}
          required={props.required}
        />
      </Popover.Target>
      <Popover.Dropdown sx={{ marginLeft: "2em" }}>
        <Stack spacing="xs">
          <Group noWrap>
            <ThemeIcon color="yellow" size="xl" variant="light">
              <BiError size={32} />
            </ThemeIcon>
            <Text>La contraseña debe cumplir los siguientes requisitos:</Text>
          </Group>
          <List withPadding>
            <List.Item>
              Tener al menos 8 caracteres (recomendamos un mínimo de 12)
            </List.Item>
            {requirements.map((req, index) => (
              <List.Item key={index}>{req.label}</List.Item>
            ))}
          </List>
          <Progress value={passwordStrength} color={strengthColor} />
          <Text
            align="right"
            size="sm"
            color={passwordStrength < 95 ? "red" : "green"}
          >
            {passwordStrength < 95
              ? "Contraseña no válida"
              : "Contraseña válida"}
          </Text>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};

export default PasswordEditInput;

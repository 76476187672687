import {
  Box,
  Checkbox,
  Divider,
  Group,
  NumberInput,
  Paper,
  ScrollArea,
  SegmentedControl,
  Select,
  SelectItem,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import React, { Fragment } from "react";
import { BiLink, BiMoveHorizontal, BiMoveVertical } from "react-icons/bi";
import { RiPaintFill } from "react-icons/ri";

import Opening from "../../models/opening";
import { MAX_MEASURE, MIN_MEASURE } from "../../utils/globalConstants";

import CrossbarSelector from "../UI/CrossbarSelector";
import ModuleRevest from "./ModuleRevest";
import { QuoteFormActionType } from "./Quote";

type QuoteFormProps = {
  formValues: QuoteFormState;
  quotePartId: number;
  className: string;
  unified?: boolean;
  brandDisplay: string;
  lineDisplay: string;
  opening: Opening;
  quoteId: number;
  colors: SelectItem[];
  glasses: SelectItem[];
  mosquitoList: SelectItem[];
  premarcList: SelectItem[];
  guideList: SelectItem[];
  modularPanelList: SelectItem[];
  hasTapajun: boolean;
  hasTapacin: boolean;
  hasCrossbar: boolean;
  hasRevest: boolean;
  mosqHasCrossbar: boolean;
  panels: number;
  pvc: boolean;
  loading: boolean;
  onChange: (action: QuoteFormActionType) => void;
};

export type QuoteFormState = {
  partId?: number;
  width?: number;
  height?: number;
  count?: number;
  color?: string;
  mosquito?: string;
  premarc?: string;
  tapajun?: boolean;
  guide?: string;
  tapacin?: number;
  side?: number;
  modules?: (string | string[])[];
  crossModeH?: string;
  crossModeV?: string;
  crossModeMos?: string;
  crossH?: number | number[];
  crossV?: number | number[];
  crossMos?: number | number[];
  crossCut?: number;
  modularPanel?: boolean;
  modularDesign?: string;
  modularSide?: number;
  modularLength?: number;
  modularOpenSide?: number;
  monorailSide?: number;
  monorailLength?: number;
};

const TYPE_MOSQUITO = "mosq";

const QuoteForm = (props: QuoteFormProps) => {
  const { quotePartId = 0, onChange, unified = false, formValues } = props;

  const formChangeHandler = (action: QuoteFormActionType) => {
    onChange({ ...action, partId: quotePartId });
  };

  const countChangeHandler = (val: number) => {
    formChangeHandler({ type: "count", value: val });
  };
  const widthChangeHandler = (val: number) => {
    formChangeHandler({ type: "width", value: val });
  };
  const heightChangeHandler = (val: number) => {
    formChangeHandler({ type: "height", value: val });
  };
  const colorChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "color", value: newValue });
  };
  const sideChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "side", value: +newValue });
  };
  const mosquitoChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "mosquito", value: newValue });
  };
  const mosquitoCrossChangeHandler = (val: number | number[]) => {
    formChangeHandler({ type: "crossMos", value: val });
  };
  const mosquitoCrossModeChangeHandler = (val: string) => {
    formChangeHandler({ type: "crossModeMos", value: val });
  };
  const premarcChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "premarc", value: newValue });
  };
  const tapajunChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    formChangeHandler({ type: "tapajun", value: e.target.checked });
  };
  const guideChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "guide", value: newValue });
  };
  const tapacinChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "tapacin", value: +newValue });
  };
  const moduleChangeHandler = (index: number, val: string) => {
    formChangeHandler({ type: "modules", value: val, index: index });
  };
  const subModuleChangeHandler = (
    index: number,
    subIndex: number,
    val: string
  ) => {
    formChangeHandler({
      type: "submodules",
      value: val,
      index: index,
      subIndex: subIndex,
    });
  };
  const crossHModeChangeHandler = (val: string) => {
    formChangeHandler({ type: "crossModeH", value: val });
  };
  const crossVModeChangeHandler = (val: string) => {
    formChangeHandler({ type: "crossModeV", value: val });
  };
  const crossHChangeHandler = (val: number | number[]) => {
    formChangeHandler({ type: "crossH", value: val });
  };
  const crossVChangeHandler = (val: number | number[]) => {
    formChangeHandler({ type: "crossV", value: val });
  };
  const crossbarCutChangeHandler = (val: number) => {
    formChangeHandler({ type: "crossCut", value: val });
  };
  const crossbarCutLengthChangeHandler = (val: number) => {
    formChangeHandler({ type: "crossCutLength", value: val });
  };
  const modularPanelChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    formChangeHandler({ type: "modularPanel", value: e.target.checked });
  };
  const modularDesignChangeHandler = (val: string) => {
    formChangeHandler({ type: "modularDesign", value: val });
  };
  const modularSideChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "modularSide", value: +newValue });
  };
  const modularLengthChangeHandler = (val: number) => {
    formChangeHandler({ type: "modularLength", value: val });
  };
  const modularOpenSideChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "modularOpenSide", value: +newValue });
  };
  const monorailSideChangeHandler = (newValue: string) => {
    formChangeHandler({ type: "monorailSide", value: +newValue });
  };
  const monorailLengthChangeHandler = (val: number) => {
    formChangeHandler({ type: "monorailLength", value: val });
  };

  const revestName =
    props.hasRevest &&
    !props.formValues.modularPanel &&
    !props.opening.extraPost
      ? "Revestimiento/Tab. postigón"
      : undefined;
  const modulesContent = formValues.modules.map((m, index) => (
    <ModuleRevest
      key={index}
      index={index}
      glasses={props.glasses}
      revestProdName={revestName}
      revest={m}
      canHaveSubmodules={props.opening.submodules}
      vertCrossbarCount={
        Array.isArray(props.formValues.crossV)
          ? props.formValues.crossV.length
          : props.formValues.crossV
      }
      isCut={
        props.formValues.crossCut === 0 ||
        (props.formValues.crossCut > 0 &&
          index >= formValues.modules.length - props.formValues.crossCut) ||
        (props.formValues.crossCut < 0 &&
          index < Math.abs(props.formValues.crossCut))
      }
      onRevestChange={moduleChangeHandler}
      onSubRevestChange={subModuleChangeHandler}
    />
  ));

  return (
    <Paper
      withBorder
      shadow={null}
      sx={{
        minWidth: "450px",
        maxWidth: "565px",
        borderWidth: "2px",
        borderColor: "lightgray",
        overflow: "hidden",
      }}
      style={{ zIndex: 1 }}
    >
      <ScrollArea
        type="auto"
        style={{ height: "470px" }}
        styles={{ viewport: { overscrollBehaviorY: "contain" } }}
      >
        <form style={{ padding: "20px 1em 24px" }}>
          {/* <Text mb="md" weight="bold">{`Diseño: ${props.designName}`}</Text> */}
          <Group mb="md">
            <Text fw="bold">{`Marca: ${props.brandDisplay || "?"}`}</Text>
            <Text fw="bold">{`Línea: ${props.lineDisplay || "?"}`}</Text>
            {props.pvc && <Text fw="bold">(PVC)</Text>}
          </Group>
          <Group
            mb="md"
            spacing="xl"
            align="flex-end"
            sx={
              unified
                ? (theme) => ({
                    backgroundColor: theme.colors.gray[2],
                    padding: 8,
                    borderRadius: 4,
                  })
                : null
            }
          >
            <NumberInput
              sx={{ width: "8em" }}
              label="Cantidad"
              min={1}
              max={499}
              icon={<Text>x</Text>}
              value={formValues.count}
              onChange={countChangeHandler}
            />
            {!props.opening.noColor && (
              <Select
                sx={{ width: "17em" }}
                label="Color"
                icon={<RiPaintFill size="20" />}
                data={props.colors}
                value={formValues.color}
                onChange={colorChangeHandler}
                maxDropdownHeight={280}
              />
            )}
            {props.unified && (
              <Tooltip
                position="right"
                width={200}
                multiline
                withArrow
                label="La cantidad y el color son compartidos por todas las aberturas que componen una abertura unificada."
              >
                <ThemeIcon mb={8} variant="light" color="gray">
                  <BiLink size={28} />
                </ThemeIcon>
              </Tooltip>
            )}
          </Group>
          <Group spacing="xl">
            <NumberInput
              sx={{ width: "10em" }}
              label="Ancho"
              min={MIN_MEASURE}
              max={MAX_MEASURE}
              icon={<BiMoveHorizontal size="24" />}
              disabled={unified || props.opening.noMeasures}
              value={formValues.width}
              onChange={widthChangeHandler}
            />
            <NumberInput
              sx={{ width: "10em" }}
              label="Alto"
              min={MIN_MEASURE}
              max={MAX_MEASURE}
              icon={<BiMoveVertical size="24" />}
              disabled={unified || props.opening.noMeasures}
              value={formValues.height}
              onChange={heightChangeHandler}
            />
          </Group>

          <Divider my="xl" />

          <Stack>
            {props.opening.chooseSide && (
              <Group>
                <Text>Lado de abrir</Text>
                <SegmentedControl
                  color="brand"
                  transitionDuration={0}
                  value={formValues.side.toString()}
                  onChange={sideChangeHandler}
                  data={[
                    { value: "-1", label: "Izquierdo" },
                    { value: "1", label: "Derecho" },
                  ]}
                />
              </Group>
            )}
            {!props.opening.noPrem && !unified && (
              <Stack spacing={4}>
                <Group>
                  <Text>Premarco</Text>
                  <Select
                    sx={{ width: "20em" }}
                    data={props.premarcList}
                    value={formValues.premarc}
                    onChange={premarcChangeHandler}
                    disabled={props.premarcList.length <= 1}
                    maxDropdownHeight={280}
                  />
                </Group>
                {props.premarcList.length <= 1 && (
                  <Text color="dimmed" size="sm">
                    No se encontraron diseños de premarcos para esta línea.
                  </Text>
                )}
              </Stack>
            )}
            {!props.opening.noMos && (
              <Stack spacing={4}>
                <Group>
                  <Text>Mosquitero</Text>
                  <Select
                    sx={{ width: "20em" }}
                    data={props.mosquitoList}
                    value={formValues.mosquito}
                    onChange={mosquitoChangeHandler}
                    disabled={props.mosquitoList.length <= 1}
                    maxDropdownHeight={280}
                  />
                </Group>
                {props.mosquitoList.length <= 1 && (
                  <Text color="dimmed" size="sm">
                    No se encontraron diseños de mosquiteros para esta línea.
                  </Text>
                )}
                {formValues.mosquito && props.mosqHasCrossbar && (
                  <Box
                    mx={12}
                    px={12}
                    sx={(theme) => ({
                      backgroundColor: theme.colors.gray[2],
                      padding: 8,
                      borderRadius: 4,
                    })}
                  >
                    <CrossbarSelector
                      label="Travesaños mosquitero"
                      crossbarMode={props.formValues.crossModeMos}
                      crossbarValue={props.formValues.crossMos}
                      onModeChange={mosquitoCrossModeChangeHandler}
                      onValueChange={mosquitoCrossChangeHandler}
                    />
                  </Box>
                )}
              </Stack>
            )}
            {props.hasTapajun && !unified && (
              <Checkbox
                sx={{ height: "2em" }}
                label="Tapajuntas"
                checked={formValues.tapajun}
                onChange={tapajunChangeHandler}
                disabled={formValues.tapacin !== 0 || formValues.guide !== ""}
              />
            )}
            {!props.opening.noGuide && !unified && (
              <Group>
                <Text>Guía cortina</Text>
                <Select
                  sx={{ width: "20em" }}
                  data={props.guideList}
                  value={formValues.guide}
                  onChange={guideChangeHandler}
                  disabled={props.guideList.length <= 1 || formValues.tapajun}
                  maxDropdownHeight={280}
                />
              </Group>
            )}
            {props.hasTapacin && !unified && (
              <Group>
                <Text>Tapacinta</Text>
                <SegmentedControl
                  color="brand"
                  transitionDuration={0}
                  value={formValues.tapacin.toString()}
                  onChange={tapacinChangeHandler}
                  data={[
                    { value: "0", label: "No" },
                    { value: "-1", label: "Izquierda" },
                    { value: "1", label: "Derecha" },
                    { value: "2", label: "Ambos" },
                  ]}
                  disabled={formValues.tapajun}
                />
              </Group>
            )}
            {props.hasCrossbar &&
              (props.opening.code !== TYPE_MOSQUITO ? (
                <Fragment>
                  <CrossbarSelector
                    label="Travesaños horizontales"
                    crossbarMode={props.formValues.crossModeH}
                    crossbarValue={props.formValues.crossH}
                    onModeChange={crossHModeChangeHandler}
                    onValueChange={crossHChangeHandler}
                    disabled={props.formValues.modularPanel}
                  />
                  <CrossbarSelector
                    label="Travesaños verticales"
                    maxCut={
                      props.formValues.crossH
                        ? typeof props.formValues.crossH === "number"
                          ? props.formValues.crossH
                          : props.formValues.crossH.length
                        : undefined
                    }
                    canBeCut
                    crossbarMode={props.formValues.crossModeV}
                    crossbarValue={props.formValues.crossV}
                    cutValue={props.formValues.crossCut}
                    onModeChange={crossVModeChangeHandler}
                    onValueChange={crossVChangeHandler}
                    onCutChange={crossbarCutChangeHandler}
                    onCutLengthChange={crossbarCutLengthChangeHandler}
                    disabled={props.formValues.modularPanel}
                  />
                </Fragment>
              ) : (
                <CrossbarSelector
                  label="Travesaños mosquitero"
                  crossbarMode={props.formValues.crossModeMos}
                  crossbarValue={props.formValues.crossMos}
                  onModeChange={mosquitoCrossModeChangeHandler}
                  onValueChange={mosquitoCrossChangeHandler}
                />
              ))}
            {!props.opening.noConfig && (
              <SimpleGrid cols={2}>{modulesContent}</SimpleGrid>
            )}
            {props.opening.modularPanel && !props.unified && (
              <Stack sx={{ width: "100%" }}>
                <Divider my="xs" />
                <Stack spacing={4}>
                  <Checkbox
                    sx={{ height: "2em" }}
                    label="Ventana patagónica"
                    checked={props.formValues.modularPanel}
                    onChange={modularPanelChangeHandler}
                    disabled={props.modularPanelList.length === 0}
                  />
                  {props.modularPanelList.length === 0 && (
                    <Text span color="dimmed" size="sm">
                      No se encontraron diseños válidos para esta línea. Sólo se
                      permite una ventana de abrir, banderola, oscilobatiente,
                      desplazable o ventiluz como parte de una ventana
                      patagónica.
                    </Text>
                  )}
                </Stack>
                {props.formValues.modularPanel && (
                  <Stack>
                    <Group>
                      <Text>Diseño</Text>
                      <Select
                        style={{ flexGrow: 1, marginRight: "1em" }}
                        placeholder="Seleccione un diseño"
                        data={props.modularPanelList}
                        value={props.formValues.modularDesign}
                        onChange={modularDesignChangeHandler}
                        maxDropdownHeight={280}
                      />
                    </Group>
                    <Group>
                      <Text>Posición</Text>
                      <SegmentedControl
                        color="brand"
                        transitionDuration={0}
                        data={[
                          { value: "-1", label: "Izquierda" },
                          { value: "1", label: "Derecha" },
                          { value: "2", label: "Ambos" },
                        ]}
                        value={props.formValues.modularSide.toString()}
                        onChange={modularSideChangeHandler}
                        disabled={!props.formValues.modularDesign}
                      />
                    </Group>
                    <Group>
                      <Text>Ancho ventana</Text>
                      <NumberInput
                        sx={{ width: "8em" }}
                        min={0}
                        max={props.formValues.width}
                        value={props.formValues.modularLength}
                        onChange={modularLengthChangeHandler}
                        disabled={!props.formValues.modularDesign}
                      />
                    </Group>
                    <Group>
                      <Text>Lado de abrir</Text>
                      <SegmentedControl
                        color="brand"
                        transitionDuration={0}
                        value={props.formValues.modularOpenSide.toString()}
                        onChange={modularOpenSideChangeHandler}
                        data={[
                          { value: "-1", label: "Izquierdo" },
                          { value: "1", label: "Derecho" },
                        ]}
                        disabled={!props.formValues.modularDesign}
                      />
                    </Group>
                    <Group>
                      <Text>Mosquitero</Text>
                      <Select
                        sx={{ width: "20em" }}
                        data={props.mosquitoList}
                        value={formValues.mosquito}
                        onChange={mosquitoChangeHandler}
                        disabled={
                          !props.formValues.modularDesign ||
                          props.mosquitoList.length <= 1
                        }
                        maxDropdownHeight={280}
                      />
                    </Group>
                  </Stack>
                )}
              </Stack>
            )}
            {props.opening.monorail && (
              <Stack>
                <Group>
                  <Text>Posición</Text>
                  <SegmentedControl
                    color="brand"
                    transitionDuration={0}
                    data={
                      props.panels === 1
                        ? [
                            { value: "-1", label: "Izquierda" },
                            { value: "1", label: "Derecha" },
                          ]
                        : [
                            { value: "2", label: "Laterales" },
                            { value: "0", label: "Centro" },
                          ]
                    }
                    value={props.formValues.monorailSide.toString()}
                    onChange={monorailSideChangeHandler}
                  />
                </Group>
                <Group>
                  <Text>Ancho corrediza</Text>
                  <NumberInput
                    sx={{ width: "8em" }}
                    min={300}
                    max={Math.floor(props.formValues.width * 0.5)}
                    value={props.formValues.monorailLength}
                    onChange={monorailLengthChangeHandler}
                  />
                </Group>
                <Group>
                  <Text>Mosquitero</Text>
                  <Select
                    sx={{ width: "20em" }}
                    data={props.mosquitoList}
                    value={formValues.mosquito}
                    onChange={mosquitoChangeHandler}
                    disabled={props.mosquitoList.length <= 1}
                    maxDropdownHeight={280}
                  />
                </Group>
              </Stack>
            )}
          </Stack>
        </form>
      </ScrollArea>
    </Paper>
  );
};

export default QuoteForm;

import {
  Box,
  Button,
  CSSObject,
  Group,
  MantineTheme,
  Modal,
  Progress,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { useEffect, useRef } from "react";
import { BiCheck, BiDownload, BiNoEntry } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

type QuoteRequestModalProps = {
  opened: boolean;
  onClose: () => void;
  data: {
    costName?: string;
    costFile?: string;
    orderName?: string;
    orderFile?: string;
    clientName?: string;
    clientFile?: string;
    materialName?: string;
    materialFile?: string;
  };
  savingQuotes: boolean;
  error: boolean;
  errorMessage?: string;
};

const downloadButtonStyles = (theme: MantineTheme): CSSObject => ({
  backgroundColor: theme.colors.brand[3],
  ":hover": {
    backgroundColor: theme.colors.brand[5],
  },
});

const HISTORY_LINK = "/history";
const DESIGN_LINK = "/design";

const QuoteRequestModal = (props: QuoteRequestModalProps) => {
  const { data = null, error, errorMessage } = props;

  const costFileLink = useRef<HTMLAnchorElement>(null);
  const orderFileLink = useRef<HTMLAnchorElement>(null);
  const clientFileLink = useRef<HTMLAnchorElement>(null);
  const materialFileLink = useRef<HTMLAnchorElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      const costData = {
        linkSource: `data:application/pdf;base64,${data.costFile}`,
        downloadLink: document.createElement("a"),
        fileName: data.costName,
      };
      const orderData = {
        linkSource: `data:application/pdf;base64,${data.orderFile}`,
        downloadLink: document.createElement("a"),
        fileName: data.orderName,
      };
      const clientData = {
        linkSource: `data:application/pdf;base64,${data.clientFile}`,
        downloadLink: document.createElement("a"),
        fileName: data.clientName,
      };
      const materialData = {
        linkSource: `data:application/pdf;base64,${data.materialFile}`,
        downloadLink: document.createElement("a"),
        fileName: data.materialName,
      };

      costData.downloadLink.href = costData.linkSource;
      costData.downloadLink.download = costData.fileName;
      orderData.downloadLink.href = orderData.linkSource;
      orderData.downloadLink.download = orderData.fileName;
      clientData.downloadLink.href = clientData.linkSource;
      clientData.downloadLink.download = clientData.fileName;
      materialData.downloadLink.href = materialData.linkSource;
      materialData.downloadLink.download = materialData.fileName;

      costFileLink.current = costData.downloadLink;
      orderFileLink.current = orderData.downloadLink;
      clientFileLink.current = clientData.downloadLink;
      materialFileLink.current = materialData.downloadLink;
    }
  }, [data]);

  const downloadCostFileHandler = () => {
    costFileLink.current.click();
  };

  const downloadOrderFileHandler = () => {
    orderFileLink.current.click();
  };

  const downloadClientFileHandler = () => {
    clientFileLink.current.click();
  };

  const downloadMaterialFileHandler = () => {
    materialFileLink.current.click();
  };

  const goToHistory = () => {
    navigate(props.savingQuotes ? HISTORY_LINK : DESIGN_LINK);
  };

  return (
    <Modal
      centered
      size={540}
      padding="md"
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={props.opened}
      onClose={props.onClose}
    >
      {!error && !data && (
        <Stack align="center" p={30}>
          <Text size="lg" align="center">
            Generando presupuesto en formato PDF...
          </Text>
          <Text size="md" align="center">
            Este proceso puede tomar varios segundos, dependiendo de la cantidad
            y complejidad de las aberturas que componen el presupuesto.
          </Text>
          <Progress
            mt="md"
            color="blue"
            size="lg"
            value={100}
            striped
            animate
            sx={{ width: "350px" }}
          />
        </Stack>
      )}
      {!error && data && (
        <Stack align="center" px={30} py={20}>
          <Stack align="center" spacing="xs">
            <ThemeIcon color="green" size="lg" radius="xl">
              <BiCheck size={32} />
            </ThemeIcon>
            <Text color="green" size="lg" align="center">
              Presupuesto generado
            </Text>
          </Stack>
          {props.savingQuotes && (
            <Text size="md" align="center">
              Los archivos se han guardado en su Historial, aunque también puede
              descargarlos ahora:
            </Text>
          )}
          <Stack
            mt="xs"
            mb="xl"
            align="stretch"
            spacing="lg"
            sx={{ width: "20em" }}
          >
            {props.data.costFile && (
              <Button
                sx={downloadButtonStyles}
                onClick={downloadCostFileHandler}
              >
                <BiDownload size={20} />
                <Text ml="7px">Costos y Ganancias</Text>
              </Button>
            )}
            {props.data.orderFile && (
              <Button
                sx={downloadButtonStyles}
                onClick={downloadOrderFileHandler}
              >
                <BiDownload size={20} />
                <Text ml="7px">Orden de Trabajo</Text>
              </Button>
            )}
            {props.data.materialFile && (
              <Button
                sx={downloadButtonStyles}
                onClick={downloadMaterialFileHandler}
              >
                <BiDownload size={20} />
                <Text ml="7px">Pedido de Materiales</Text>
              </Button>
            )}
            {props.data.clientFile && (
              <Button
                sx={downloadButtonStyles}
                onClick={downloadClientFileHandler}
              >
                <BiDownload size={20} />
                <Text ml="7px">Presupuesto para el Cliente</Text>
              </Button>
            )}
          </Stack>
          <Group>
            <Button sx={{ width: "14em" }} onClick={goToHistory}>
              {props.savingQuotes ? "Ir a Historial" : "Ir a Diseño"}
            </Button>
            <Button
              variant="outline"
              sx={{ width: "8em" }}
              onClick={props.onClose}
            >
              Volver
            </Button>
          </Group>
        </Stack>
      )}
      {error && (
        <Stack align="center" px={30} p={20}>
          <Stack align="center" spacing="xs">
            <ThemeIcon color="red" size="xl" radius="xl">
              <BiNoEntry size={32} />
            </ThemeIcon>
            <Text color="red" size="lg" align="center">
              ¡Ha ocurrido un error inesperado!
            </Text>
          </Stack>
          <Text align="center">
            Por favor inténtelo una vez más, o comuníquese con nosotros para que
            revisemos el problema.
          </Text>
          {errorMessage && (
            <ScrollArea
              type="auto"
              offsetScrollbars
              style={{
                height: "100px",
              }}
            >
              <Box
                p="md"
                sx={(theme) => ({
                  backgroundColor: theme.colors.gray[1],
                  minHeight: "30px",
                })}
              >
                <Text align="center" size="sm" color="red.8">
                  {errorMessage}
                </Text>
              </Box>
            </ScrollArea>
          )}
          <Button mt="xs" sx={{ width: "10em" }} onClick={props.onClose}>
            Aceptar
          </Button>
        </Stack>
      )}
    </Modal>
  );
};

export default QuoteRequestModal;

import Opening from "../models/opening";

export const MIN_MEASURE = 200;
export const MAX_MEASURE = 6000;

export const PRINT_SAFE_FILTER =
  /^[a-zA-Z0-9!@#$&%|()[\]{}\\\-_`.'+=~<>°,/¿?¡!*;:ñáéíóúÑÁÉÍÓÚüÜöÖ\s"]*$/;

export type Measures = {
  width: number;
  height: number;
};

export type QuotePartLayout = {
  designId: string;
  x: number;
  y: number;
  w: number;
  h: number;
};

export type Country = {
  code: string;
  name: string;
  prefix: string;
};

export type Plan = {
  code: string;
  name: string;
  price: number;
  priceUsd: number;
  recommended: boolean;
  description: string[];
  advFeatures: boolean;
  intl: boolean;
  brandCount?: number;
};

export enum ROLES {
  USER = "user",
  GUEST = "guest",
  ADMIN = "admin",
}

export const COUNTRY_FLAGS: { code: string; flag: string }[] = [
  { code: "ARG", flag: "🇦🇷" },
  { code: "CHL", flag: "🇨🇱" },
  { code: "BOL", flag: "🇧🇴" },
  { code: "PER", flag: "🇵🇪" },
  { code: "MEX", flag: "🇲🇽" },
];

export const OPENINGS: Opening[] = [
  {
    code: "corr",
    name: "Corrediza",
    minPan: 2,
    maxPan: 4,
    defSize: [750, 1100],
  },
  {
    code: "copo",
    name: "Corrediza c/ pos. corred.",
    minPan: 2,
    maxPan: 2,
    defSize: [750, 1100],
    extraPost: true,
  },
  {
    code: "coab",
    name: "Corrediza c/ pos. de abr.",
    minPan: 2,
    maxPan: 2,
    defSize: [750, 1100],
    extraPost: true,
  },
  {
    code: "fijo",
    name: "Paño fijo",
    minPan: 1,
    maxPan: 1,
    hidePan: true,
    noMos: true,
    modularPanel: true,
    defSize: [1500, 1100],
  },
  {
    code: "vluz",
    name: "Ventiluz",
    minPan: 1,
    maxPan: 1,
    noRevest: true,
    defSize: [600, 600],
  },
  {
    code: "desp",
    name: "Desplazable",
    minPan: 1,
    maxPan: 1,
    noRevest: true,
    defSize: [600, 600],
  },
  {
    code: "band",
    name: "Banderola",
    minPan: 1,
    maxPan: 1,
    noRevest: true,
    defSize: [600, 600],
  },
  {
    code: "osci",
    name: "Oscilobatiente",
    minPan: 1,
    maxPan: 2,
    noRevest: true,
    chooseSide: true,
    defSize: [600, 1000],
  },
  {
    code: "abri",
    name: "Vent. de abrir / Postigón",
    minPan: 1,
    maxPan: 4,
    onlyPost: true,
    chooseSide: true,
    defSize: [600, 1000],
  },
  {
    code: "guil",
    name: "Guillotina",
    minPan: 2,
    maxPan: 2,
    noCrossbar: true,
    noRevest: true,
    panelOri: "v",
    tallerThanWider: true,
    defSize: [600, 1100],
  },
  {
    code: "mono",
    name: "Monorriel",
    minPan: 1,
    maxPan: 2,
    noMos: true,
    noCrossbar: true,
    monorail: true,
    defSize: [1500, 1100],
  },
  {
    code: "pcor",
    name: "Puerta vent. corrediza",
    minPan: 2,
    maxPan: 4,
    threeSidedTapajun: true,
    tallerThanWider: true,
    defSize: [750, 2000],
  },
  {
    code: "preb",
    name: "Puerta rebatible",
    minPan: 1,
    maxPan: 4,
    chooseSide: true,
    threeSidedTapajun: true,
    tallerThanWider: true,
    submodules: true,
    defSize: [800, 2050],
  },
  {
    code: "mosq",
    name: "Mosquitero",
    minPan: 1,
    maxPan: 1,
    hidePan: true,
    noGuide: true,
    noTapacin: true,
    noPrem: true,
    noMos: true,
    noConfig: true,
    noRevest: true,
    noGlass: true,
    noEmbed: true,
    catLimit: ["mos", "a"],
    embeddable: true,
    defSize: [750, 1100],
  },
  {
    code: "prem",
    name: "Premarco",
    minPan: 1,
    maxPan: 1,
    hidePan: true,
    noMos: true,
    noGuide: true,
    noTapacin: true,
    noCrossbar: true,
    noRevest: true,
    noGlass: true,
    noPrem: true,
    noColor: true,
    noEmbed: true,
    noConfig: true,
    catLimit: ["pre", "a"],
    embeddable: true,
    defSize: [1500, 1100],
  },
  {
    code: "comp",
    name: "Compacto",
    minPan: 1,
    maxPan: 1,
    noConfig: true,
    noMeasures: true,
    hidden: true,
  },
];

export enum ProductCategory {
  FRAME = "mar",
  PANEL = "hoj",
  MOSQUITO = "mos",
  PREMARC = "pre",
  GUIDE = "gui",
  CORT = "cor",
  TAPAJUN = "tap",
  CVD = "cvd",
  REVEST = "rev",
  POST = "pos",
  GLASS = "vid",
  POLIC = "pol",
  PNL = "pnl",
  TEJM = "tej",
  PAINT = "pin",
  ANOD = "ano",
  ACOP = "aco",
  ACC = "a",
  COMPACT = "com",
  LABOR = "lab",
}

export enum ProductSpecialTag {
  CROSSBAR = "tra",
  TAPACIN = "tcn",
  INVISIBILE = "inv",
  CAMERA = "cam",
  THIN = "fin"
}

export const DESIGN_CATEGORIES: [string, string][] = [
  [ProductCategory.FRAME, "Marco"],
  [ProductCategory.PANEL, "Hoja"],
  [ProductCategory.PREMARC, "Premarco"],
  [ProductCategory.TAPAJUN, "Tapajuntas"],
  [ProductCategory.MOSQUITO, "Mosquitero"],
  [ProductCategory.CVD, "Contravidrios"],
  [ProductCategory.CORT, "Cortina"],
  [ProductCategory.ACC, "Accesorios"],
];

export const COMPACT_MODELS = [
  { code: "137", name: "Compacto 137" },
  { code: "165", name: "Compacto 165" },
  { code: "180", name: "Compacto 180" },
  { code: "205", name: "Compacto 205" },
];

export class CostSections {
  alu? = 0;
  pvc? = 0;
  acc? = 0;
  vid? = 0;
  pol? = 0;
  pnl? = 0;
  pin? = 0;
  ano? = 0;
  tej? = 0;
  com? = 0;
  lab? = 0;

  constructor(
    alu: number = 0,
    acc: number = 0,
    vid: number = 0,
    pol: number = 0,
    pnl: number = 0,
    pin: number = 0,
    ano: number = 0,
    tej: number = 0,
    com: number = 0,
    lab: number = 0
  ) {
    this.alu = alu;
    this.acc = acc;
    this.vid = vid;
    this.pol = pol;
    this.pnl = pnl;
    this.pin = pin;
    this.ano = ano;
    this.tej = tej;
    this.com = com;
    this.lab = lab;
  }

  // getTotalSum(): number {
  //   return this.alu + this.acc + this.vid + this.pol + this.pin + this.ano + this.tej;
  // }
}

import {
  Button,
  Group,
  Modal,
  NumberInput,
  ScrollArea,
  SimpleGrid,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { BiDollar, BiEdit } from "react-icons/bi";
import Product from "../../models/product";
import { formatCurrencyDisplay } from "../../utils/amountFormat";
import { AiOutlinePercentage } from "react-icons/ai";
import PaintPrices from "../../models/paint-prices";

type CustomBarsTableProps = {
  itemType: "line" | "bar";
  items: CustomBarItem[];
  colors: Product[];
  pvc: boolean;
  roundCosts: boolean;
  mt?: number;
  showPercentageButton?: boolean;
  currentBrandName?: string;
  onCustomSave: (id: string, data: PaintPrices, type: string) => Promise<any>;
  onPercentageApply?: (percentage: number) => Promise<boolean>;
};

type PaintProduct = {
  paintId: string;
  ord: number;
  label: string;
};

export type CustomBarItem = {
  id: string;
  name: string;
  prices: PaintPrices;
  code?: string;
};

const FIRST_COL_WIDTH = 125;
const COL_WIDTH = 110;

const MIN_PERCENTAGE = -100;
const MAX_PERCENTAGE = 100;

const CustomBarsTable = (props: CustomBarsTableProps) => {
  const [colorColumns, setColorColumns] = useState<PaintProduct[]>([]);
  const [tableWidth, setTableWidth] = useState(950);

  const [selectedItem, setSelectedItem] = useState<CustomBarItem>(null);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalPercentageOpen, setModalPercentageOpen] =
    useState<boolean>(false);

  const [prices, setPrices] = useState<PaintPrices>(undefined);
  const [editPercentage, setEditPercentage] = useState<number>(undefined);

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (props.colors && props.colors.length > 0) {
      const filteredColors: PaintProduct[] = props.colors
        .filter((c) => (props.pvc ? c.spc === "pvc" : !c.spc))
        .sort((a, b) => a.ord - b.ord)
        .map((prod) => ({
          paintId: prod.paintId,
          ord: prod.ord,
          label: prod.code,
        }));
      if (!props.pvc) {
        filteredColors.unshift({
          paintId: "sp",
          ord: 0,
          label: "S/ pintar",
        });
      }
      const colCount = filteredColors.length + +!props.pvc;
      setColorColumns(filteredColors);
      setTableWidth(FIRST_COL_WIDTH + colCount * COL_WIDTH);
    }
  }, [props.colors, props.pvc]);

  useEffect(() => {
    setSelectedItem(null);
  }, [props.items]);

  const displayTablePrice = (price: number | undefined) => {
    if (price) return formatCurrencyDisplay(price, false);
    else return "-";
  };

  const rowClickHandler = (item: CustomBarItem) => {
    setSelectedItem(item);
  };

  const percentageButtonHandler = () => {
    setError(false);
    setEditPercentage(undefined);
    setModalPercentageOpen(true);
  };

  const editButtonHandler = () => {
    setError(false);
    setPrices({ ...selectedItem.prices });
    setModalEditOpen(true);
  };

  const onPriceChange = (id: string, newPrice: number) => {
    setPrices((prev) => ({
      ...prev,
      [id]: newPrice,
    }));
  };

  const saveButtonHandler = async () => {
    setSaving(true);
    const saveResponse = await props.onCustomSave(
      selectedItem.id,
      prices,
      props.itemType
    );
    if (saveResponse) {
      setModalEditOpen(false);
    } else {
      setError(true);
    }
    setSaving(false);
  };

  const applyPercentageHandler = async () => {
    setSaving(true);
    const saveResponse = await props.onPercentageApply(editPercentage);
    if (saveResponse) {
      setModalPercentageOpen(false);
    } else {
      setError(true);
    }
    setSaving(false);
  };

  return (
    <Stack>
      <Modal
        title="Editar costos de barras"
        size="54em"
        padding="xl"
        opened={modalEditOpen}
        onClose={() => setModalEditOpen(false)}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={true}
      >
        <Stack>
          {selectedItem && (
            <Text fw="bold">{`${
              props.itemType === "bar"
                ? "[" + selectedItem.code + "] "
                : "Línea: "
            }${selectedItem.name}`}</Text>
          )}
          <SimpleGrid cols={5} spacing="xl" verticalSpacing="md">
            {colorColumns.map((col) => (
              <NumberInput
                key={col.paintId}
                size="sm"
                label={col.label}
                min={0}
                max={999999}
                precision={props.roundCosts ? 0 : 2}
                decimalSeparator=","
                icon={<BiDollar size="20" />}
                value={prices?.[col.paintId] || 0}
                onChange={(value) => onPriceChange(col.paintId, value || 0)}
              />
            ))}
          </SimpleGrid>
          <Group mt={16} position="right">
            <Button
              sx={{ width: "10em" }}
              onClick={saveButtonHandler}
              loading={saving}
            >
              Guardar
            </Button>
            <Button
              sx={{ width: "8em" }}
              variant="outline"
              onClick={() => setModalEditOpen(false)}
              disabled={saving}
            >
              Cancelar
            </Button>
          </Group>
          {error && (
            <Text align="center" color="red">
              Ocurrió un error al guardar.
            </Text>
          )}
        </Stack>
      </Modal>

      <Modal
        size="lg"
        padding="xl"
        opened={modalPercentageOpen}
        onClose={() => setModalPercentageOpen(false)}
        withCloseButton={false}
        closeOnEscape={false}
        closeOnClickOutside={false}
      >
        <Stack align="center">
          <Text>
            Ingrese un porcentaje para modificar en conjunto el costo de{" "}
            <Text span inherit fw="bold">
              todos los productos de la marca actual.
            </Text>{" "}
            Puede ingresar un porcentaje negativo.
          </Text>
          {props.currentBrandName && (
            <Text my={0}>
              Marca: <strong>{props.currentBrandName}</strong>
            </Text>
          )}
          <NumberInput
            sx={{ width: "7em" }}
            step={1}
            min={MIN_PERCENTAGE}
            max={MAX_PERCENTAGE}
            rightSection={<AiOutlinePercentage color="gray" />}
            value={editPercentage}
            onChange={(value: number) => setEditPercentage(value)}
            disabled={saving}
          />
          <Group mt="sm">
            <Button
              sx={{ width: "10em" }}
              loading={saving}
              disabled={
                !editPercentage ||
                editPercentage < MIN_PERCENTAGE ||
                editPercentage > MAX_PERCENTAGE ||
                editPercentage === 0
              }
              onClick={applyPercentageHandler}
            >
              Confirmar
            </Button>
            <Button
              sx={{ width: "10em" }}
              variant="outline"
              onClick={() => setModalPercentageOpen(false)}
              disabled={saving}
            >
              Cancelar
            </Button>
          </Group>
          {error && (
            <Text align="center" color="red">
              Ocurrió un error al guardar.
            </Text>
          )}
        </Stack>
      </Modal>

      <ScrollArea
        mt={props.mt}
        w={950}
        h={390}
        style={{ border: "1px solid darkgray" }}
      >
        <Table
          w={tableWidth}
          color="brand"
          highlightOnHover
          sx={{ userSelect: "none" }}
        >
          <thead
            style={{
              position: "sticky",
              top: "0px",
              backgroundColor: "var(--secondary)",
            }}
          >
            <tr>
              <th style={{ width: `${FIRST_COL_WIDTH}px` }}></th>
              {colorColumns.map((col) => (
                <th key={col.paintId} style={{ width: `${COL_WIDTH}px` }}>
                  {col.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.items.map((item) => (
              <tr
                key={item.id}
                onClick={() => rowClickHandler(item)}
                style={{
                  backgroundColor:
                    selectedItem && item.id === selectedItem.id
                      ? "lightblue"
                      : "white",
                }}
              >
                <td>{item.code}</td>
                {colorColumns.map((col) => (
                  <td key={col.paintId}>
                    {displayTablePrice(item.prices?.[col.paintId])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>

      <Group position="right" sx={{ flexGrow: 1 }}>
        {props.showPercentageButton && (
          <Button variant="outline" onClick={percentageButtonHandler}>
            Modificar costos con porcentaje
          </Button>
        )}
        <Button
          sx={{ width: "10em" }}
          onClick={editButtonHandler}
          disabled={!selectedItem}
        >
          <BiEdit size={20} />
          <Text size="sm" ml={4}>
            Editar
          </Text>
        </Button>
      </Group>
    </Stack>
  );
};

export default CustomBarsTable;

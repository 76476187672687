import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import {
  BiEditAlt,
  BiArrowToBottom,
  BiArrowToTop,
  BiError,
  BiInfoCircle,
} from "react-icons/bi";
import {
  Accordion,
  ActionIcon,
  Affix,
  Alert,
  Badge,
  Group,
  Loader,
  Stack,
  Text,
  Title,
  Transition,
} from "@mantine/core";
import { useLocalStorage, useScrollIntoView } from "@mantine/hooks";
import { useMutation, useQuery } from "react-query";

import {
  getBrands,
  getCustomProducts,
  getDesigns,
  getExtraProducts,
  getHistoryQuoteState,
  precalculateQuoteCost,
  requestQuote,
} from "../../services/services";
import UserContext from "../../store/user-context";
import Item from "../../models/item";
import { QuoteFormState } from "../../components/Quote/QuoteForm";
import { ProductCategory, QuotePartLayout } from "../../utils/globalConstants";
import { getArticleCodeFromIndex } from "../../utils/articleHelpers";

import Quote from "../../components/Quote/Quote";
import QuoteControlBox from "../../components/Quote/QuoteControlBox";
import QuoteRequestModal from "../../components/Quote/QuoteRequestModal";
import QuoteRenameModal from "../../components/Quote/QuoteRenameModal";
import QuoteAccordion from "../../components/Quote/QuoteAccordion";
import QuoteBonusTable from "../../components/Quote/QuoteBonusTable";
import Compact, { CompactFormState } from "../../components/Quote/Compact";
import Design from "../../models/design";
import Product from "../../models/product";
import HistoryQuoteState from "../../models/history-quote-state";

import styles from "./NewQuote.module.css";

const MY_DESIGNS_LINK = "/design";
const OPEN_MOSQUITO = "mosq";
const OPEN_PREMARC = "prem";
const OPEN_MODULAR_CONTENT = ["abri", "band", "osci", "vluz", "desp"];
const MAX_ARTICLE_LENGTH =
  Number(process.env.REACT_APP_QUOTE_MAX_ARTICLES) || 20;

export type QuotePart = {
  partId: number;
  designId: string;
  form?: QuoteFormState;
  layout?: {
    x: number;
    y: number;
    w: number;
    h: number;
  };
};

type SingleQuoteState = {
  quoteId: number;
  articleCode: string;
  order: number;
  quoteName?: string;
  parts: QuotePart[];
  unified?: boolean;
  unifiedOps?: {
    premarc: string;
    tapajun: boolean;
  };
  acops?: Item[];
};

type SingleCompactState = {
  compactId: number;
  articleCode: string;
  modelId: string;
  form?: CompactFormState;
  compactName?: string;
};

const NewQuote = () => {
  const userCtx = useContext(UserContext);

  const [showPaintSystemMessage, setShowPaintSystemMessage] =
    useLocalStorage<boolean>({
      key: "msg_newPaintSystem",
      defaultValue: true,
    });

  const [quoteElements, setQuoteElements] = useState<SingleQuoteState[]>([]);
  const [compactElements, setCompactElements] = useState<SingleCompactState[]>(
    []
  );
  const [bonusItems, setBonusItems] = useState<Item[]>([]);
  const [openedQuote, setOpenedQuote] = useState<string | null>(null);
  const [openedCompact, setOpenedCompact] = useState<string | null>(null);
  const [renameQuoteModal, setRenameQuoteModal] = useState<{
    id: number;
    order: number;
    current: string;
    compact: boolean;
  } | null>(null);

  // Data
  const [mosquitoDesignData, setMosquitoDesignData] = useState<Design[]>([]);
  const [premarcDesignData, setPremarcDesignData] = useState<Design[]>([]);
  const [modularPanelDesignData, setModularPanelDesignData] = useState<
    Design[]
  >([]);
  const [extraProductData, setExtraProductData] = useState<Product[]>([]);
  const [acopProductData, setAcopProductData] = useState<Product[]>([]);
  const [editMetadata, setEditMetadata] = useState(null);
  const [hasLoadError, setHasLoadError] = useState(false);

  const nextQuoteIndex = useRef(0);
  const nextCompactIndex = useRef(0);
  const nextBonusItemIndex = useRef(0);

  const { scrollIntoView: scrollToTop, targetRef: topRef } =
    useScrollIntoView<HTMLDivElement>({
      duration: 600,
    });
  const { scrollIntoView: scrollToBottom, targetRef: bottomRef } =
    useScrollIntoView<HTMLDivElement>({
      offset: 50,
      duration: 600,
    });

  // Print
  const [printContent, setPrintContent] = useState(null);
  const [precalculatedCost, setPrecalculatedCost] = useState(0);
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const [printError, setPrintError] = useState("");

  // Edit state
  const params = useParams();
  const [quoteEditId, setQuoteEditId] = useState<string>(undefined);

  // Queries
  const { data: designData, isFetching: isLoading } = useQuery(
    "designs",
    () => getDesigns(userCtx.data.id, userCtx.token),
    {
      placeholderData: [],
      onSuccess: (data) => {
        setMosquitoDesignData(data.filter((d) => d.open === OPEN_MOSQUITO));
        setPremarcDesignData(data.filter((d) => d.open === OPEN_PREMARC));
        setModularPanelDesignData(
          data.filter(
            (d) => OPEN_MODULAR_CONTENT.includes(d.open) && d.pan === 1
          )
        );
      },
    }
  );
  useQuery("extra", () => getExtraProducts(userCtx.token), {
    onSuccess: (data) => {
      setExtraProductData(data.filter((p) => p.cat !== ProductCategory.ACOP));
      setAcopProductData(data.filter((p) => p.cat === ProductCategory.ACOP));
    },
  });
  const { data: customProductData } = useQuery(
    "custom",
    () => getCustomProducts(userCtx.token),
    {
      placeholderData: [],
    }
  );
  const { data: brandData } = useQuery(
    "brands",
    () => getBrands(userCtx.token),
    {
      placeholderData: [],
    }
  );
  const { isFetching: isLoadingEdit } = useQuery(
    ["quote-edit", quoteEditId],
    () => getHistoryQuoteState(quoteEditId, userCtx.token),
    {
      enabled: Boolean(quoteEditId),
      onSuccess: (data: HistoryQuoteState) => {
        let quoteIndex = 0;
        let compactIndex = 0;
        let bonusIndex = 0;
        const indexedQuotes = data.articles.quotes.map((q) => ({
          ...q,
          quoteId: quoteIndex++,
        }));
        const indexedCompacts = data.articles.compacts.map((c) => ({
          ...c,
          compactId: compactIndex++,
        }));
        const indexedBonus = data.bonus.map((b) => ({
          ...b,
          itemId: bonusIndex++,
        }));
        nextQuoteIndex.current = quoteIndex;
        nextCompactIndex.current = compactIndex;
        nextBonusItemIndex.current = bonusIndex;
        setQuoteElements(indexedQuotes);
        setCompactElements(indexedCompacts);
        setBonusItems(indexedBonus);
        setEditMetadata(data.meta);
      },
    }
  );
  const precalculateCostMutation = useMutation(
    (body: any) => precalculateQuoteCost(body, userCtx.token),
    {
      onSuccess: (data) => setPrecalculatedCost(data.cost),
      onError: (error: Error) => {
        setPrecalculatedCost(-1);
        setPrintError(error.message);
      },
    }
  );
  const requestQuoteMutation = useMutation(
    (body: any) => requestQuote(body, userCtx.token),
    {
      onMutate: () => setPrintError(""),
      onSuccess: (data) =>
        setPrintContent({
          costName: data.costFile?.filename || "",
          costFile: data.costFile?.content || "",
          orderName: data.orderFile?.filename || "",
          orderFile: data.orderFile?.content || "",
          clientName: data.clientFile?.filename || "",
          clientFile: data.clientFile?.content || "",
          materialName: data.materialFile?.filename || "",
          materialFile: data.materialFile?.content || "",
        }),
      onError: (error: Error) => {
        setPrintError(error.message);
      },
    }
  );

  // Prepare edit quote at start (if quoteId param is set)
  useEffect(() => {
    const editId = params.quoteId;
    if (editId) {
      setQuoteEditId(editId);
    }
  }, [params.quoteId]);
  // Quote index counter
  useEffect(() => {
    if (quoteElements.length > 0) nextQuoteIndex.current += 1;
    else nextQuoteIndex.current = 0;
  }, [quoteElements.length]);
  // Compact index counter
  useEffect(() => {
    if (compactElements.length > 0) nextCompactIndex.current += 1;
    else nextCompactIndex.current = 0;
  }, [compactElements.length]);
  // Bonus item index counter
  useEffect(() => {
    if (bonusItems.length > 0) nextBonusItemIndex.current += 1;
    else nextBonusItemIndex.current = 0;
  }, [bonusItems.length]);

  const addQuoteHandler = (designId: string | QuotePartLayout[]) => {
    const name =
      typeof designId === "string"
        ? designData.find((d) => d._id === designId).name
        : "Abertura unificada";
    setQuoteElements((prevState) => {
      let nextPartId = 0;
      const articleCode = getArticleCodeFromIndex(prevState.length) || "";
      const newOrder = prevState.length + 1;
      const isUnified = typeof designId !== "string";
      return [
        ...prevState,
        {
          quoteId: nextQuoteIndex.current,
          articleCode: articleCode,
          order: newOrder,
          quoteName: name,
          parts: !isUnified
            ? [{ designId, partId: 0 }]
            : designId.map((partLayout) => ({
                designId: partLayout.designId,
                partId: nextPartId++,
                layout: {
                  x: partLayout.x,
                  y: partLayout.y,
                  w: partLayout.w,
                  h: partLayout.h,
                },
              })),
          unified: isUnified,
          unifiedOps: { premarc: "", tapajun: false },
        },
      ];
    });
    setOpenedQuote(nextQuoteIndex.current.toString());
    setPrecalculatedCost(0);
  };

  const quoteAppliedHandler = useCallback(
    (
      quoteIndex: number,
      partIndex: number,
      formState: QuoteFormState | QuoteFormState[],
      unifiedPremarc = "",
      unifiedTapajun = false
    ) => {
      setQuoteElements((prevState) => {
        return prevState.map((q) => {
          if (q.quoteId === quoteIndex) {
            if (Array.isArray(formState)) {
              q.parts = q.parts.map((part) => {
                const initialState = formState.find(
                  (f) => f.partId === part.partId
                );
                part.form = initialState;
                return part;
              });
            } else {
              q.parts = q.parts.map((part) => {
                if (part.partId === partIndex) {
                  part.form = formState;
                }
                return part;
              });
            }
            q.unifiedOps.premarc = unifiedPremarc;
            q.unifiedOps.tapajun = unifiedTapajun;
          }
          return q;
        });
      });
      setPrecalculatedCost(0);
    },
    []
  );

  const quoteAcopsChangedHandler = (quoteId: number, acops: Item[]) => {
    setQuoteElements((prevState) => {
      return prevState.map((q) => {
        if (q.quoteId === quoteId) {
          q.acops = acops;
        }
        return q;
      });
    });
    setPrecalculatedCost(0);
  };

  const renameQuoteHandler = (quoteId: number, compact: boolean) => {
    if (!compact) {
      const element = quoteElements.find((q) => q.quoteId === quoteId);
      setRenameQuoteModal({
        id: quoteId,
        order: element.order,
        current: element.articleCode,
        compact,
      });
    } else {
      const element = compactElements.find((c) => c.compactId === quoteId);
      setRenameQuoteModal({
        id: quoteId,
        order: 0,
        current: element.articleCode,
        compact,
      });
    }
  };

  const renameConfirmHandler = (
    id: number,
    newOrder: number,
    newCode: string,
    compact: boolean
  ) => {
    if (!newCode) return;
    if (!compact) {
      setQuoteElements((prevState) => {
        const orderChanged =
          prevState.find((q) => q.quoteId === id).order !== newOrder;
        let orderIndex = newOrder === 1 ? 2 : 1;
        const newState = prevState.map((q) => {
          if (q.quoteId === id) {
            q.articleCode = newCode;
            q.order = newOrder;
          } else if (orderChanged) {
            q.order = orderIndex === newOrder ? ++orderIndex : orderIndex;
            orderIndex++;
          }
          return q;
        });
        return orderChanged
          ? newState.sort((a, b) => a.order - b.order)
          : newState;
      });
    } else {
      setCompactElements((prevState) => {
        return prevState.map((c) => {
          if (c.compactId === id) {
            c.articleCode = newCode;
          }
          return c;
        });
      });
    }
    setRenameQuoteModal(null);
  };

  const cloneQuoteHandler = (quoteId: number) => {
    setQuoteElements((prevState) => {
      const quote = prevState.find((element) => element.quoteId === quoteId);
      const newQuote: SingleQuoteState = JSON.parse(JSON.stringify(quote));
      newQuote.quoteId = nextQuoteIndex.current;
      newQuote.articleCode = getArticleCodeFromIndex(prevState.length) || "";
      newQuote.order = prevState.length + 1;
      return [...prevState, newQuote];
    });
    setOpenedQuote(nextQuoteIndex.current.toString());
    setPrecalculatedCost(0);
  };

  const removeQuoteHandler = (quoteId: number) => {
    setQuoteElements((prevState) => {
      let newOrder = 1;
      const newState = prevState
        .filter((element) => element.quoteId !== quoteId)
        .map((element) => {
          element.order = newOrder;
          newOrder++;
          return element;
        });
      return newState;
    });
    setOpenedQuote(null);
    setPrecalculatedCost(0);
  };

  const loadQuoteErrorHandler = (quoteId: number) => {
    removeQuoteHandler(quoteId);
    setHasLoadError(true);
  };

  const addCompactHandler = (compactModelId: string) => {
    setCompactElements((prevState) => {
      const articleCode = `COM-${
        getArticleCodeFromIndex(prevState.length) || ""
      }`;
      return [
        ...prevState,
        {
          compactId: nextCompactIndex.current,
          articleCode: articleCode,
          modelId: compactModelId,
          compactName: "Compacto",
        },
      ];
    });
    setOpenedCompact(nextCompactIndex.current.toString());
    setPrecalculatedCost(0);
  };

  const compactAppliedHandler = useCallback(
    (compactIndex: number, formState: CompactFormState) => {
      setCompactElements((prevState) => {
        return prevState.map((c) => {
          if (c.compactId === compactIndex) {
            c.form = formState;
          }
          return c;
        });
      });
      setPrecalculatedCost(0);
    },
    []
  );

  const removeCompactHandler = (id: number) => {
    setCompactElements((prev) => prev.filter((e) => e.compactId !== id));
    setPrecalculatedCost(0);
  };

  const addBonusItemHandler = (name: string, count: number, cost: number) => {
    setBonusItems((prev) =>
      prev.concat({ itemId: nextBonusItemIndex.current, name, count, cost })
    );
    setPrecalculatedCost(0);
  };

  const removeBonusItemHandler = (id: number) => {
    setBonusItems((prev) => prev.filter((i) => i.itemId !== id));
    setPrecalculatedCost(0);
  };

  const precalculateCostHandler = async (
    priceModifier: number,
    includeLaborCost: boolean
  ) => {
    const body = {
      quotes: quoteElements,
      compacts: compactElements,
      bonus: bonusItems,
      optimizeCuts: false,
      laborCost: includeLaborCost,
      priceModifier,
      generateFiles: false,
    };
    precalculateCostMutation.mutate(body);
  };

  const finalizeQuoteHandler = async (
    quoteClientName: string,
    quoteDate: Date,
    priceModifier: number,
    quoteExtraText: string,
    calculateCutting: boolean,
    includeLaborCost: boolean
  ) => {
    setPrintContent(null);
    setOpenPrintModal(true);
    scrollToTop({ alignment: "start" });
    const body = {
      quotes: quoteElements,
      compacts: compactElements,
      clientName: quoteClientName,
      extraText: quoteExtraText,
      date: quoteDate,
      priceModifier,
      bonus: bonusItems,
      optimizeCuts: calculateCutting,
      laborCost: includeLaborCost,
      originalQuoteId: quoteEditId,
    };
    requestQuoteMutation.mutate(body);
    setPrecalculatedCost(0);
  };

  const closeRequestModalHandler = () => {
    setOpenPrintModal(false);
  };

  const designDropdownContent: {
    value: string;
    label: string;
    brand: string;
    line: string;
    type: string;
  }[] = designData.map((design) => {
    return {
      value: design._id,
      label: design.name,
      brand: design.brand,
      line: design.line,
      type: design.open,
    };
  });

  let quotesContent: ReactElement | ReactElement[];
  if (isLoading || isLoadingEdit) {
    quotesContent = <Text align="center">Cargando...</Text>;
  } else {
    if (designData.length === 0) {
      quotesContent = (
        <Stack mt="xl" spacing="md">
          <Alert
            title="No se encontraron diseños guardados"
            color="red"
            icon={<BiError size={32} />}
          >
            Antes de comenzar a armar presupuestos, debe crear al menos un
            diseño en la sección Diseño.
          </Alert>
          {!userCtx.isGuest && (
            <Link to={MY_DESIGNS_LINK}>
              <BiEditAlt size="20" /> Ir a Mis Diseños
            </Link>
          )}
        </Stack>
      );
    } else {
      if (quoteElements.length > 0) {
        quotesContent = (
          <QuoteAccordion value={openedQuote} onChange={setOpenedQuote}>
            {quoteElements.map((quote) => (
              <Accordion.Item
                key={quote.quoteId}
                value={quote.quoteId.toString()}
              >
                <Accordion.Control>
                  <Group pr="lg" spacing="sm">
                    <Badge color="brand" radius="sm" size="lg">
                      {quote.articleCode}
                    </Badge>
                    {quote.quoteName || quote.quoteId.toString()}
                    <Badge ml="auto" variant="light" color="dark" size="lg">
                      {quote.order}
                    </Badge>
                  </Group>
                </Accordion.Control>
                <Accordion.Panel>
                  <Quote
                    quoteId={quote.quoteId}
                    parts={quote.parts}
                    opened={quote.quoteId.toString() === openedQuote}
                    brands={brandData}
                    extra={extraProductData}
                    custom={customProductData}
                    acops={quote.unified ? acopProductData : []}
                    premarcs={premarcDesignData}
                    mosquitos={mosquitoDesignData}
                    modularPanels={modularPanelDesignData}
                    initialAcops={quote.acops}
                    initialUnifiedOps={quote.unifiedOps}
                    canClone={quoteElements.length < MAX_ARTICLE_LENGTH}
                    onRename={renameQuoteHandler}
                    onClone={cloneQuoteHandler}
                    onRemove={removeQuoteHandler}
                    onLoadError={loadQuoteErrorHandler}
                    onQuoteApplied={quoteAppliedHandler}
                    onAcopsChanged={quoteAcopsChangedHandler}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            ))}
          </QuoteAccordion>
        );
      }
    }
  }

  let compactsContent: ReactElement | ReactElement[];
  if (!isLoading && compactElements.length > 0) {
    compactsContent = (
      <QuoteAccordion value={openedCompact} onChange={setOpenedCompact}>
        {compactElements.map((compact) => (
          <Accordion.Item
            key={compact.compactId}
            value={compact.compactId.toString()}
          >
            <Accordion.Control>
              <Group spacing="sm">
                <Badge color="dark.4" radius="sm" size="lg">
                  {compact.articleCode}
                </Badge>
                {compact.compactName || compact.compactId.toString()}
              </Group>
            </Accordion.Control>
            <Accordion.Panel>
              <Compact
                form={compact.form}
                compactId={compact.compactId}
                compactModel={compact.modelId}
                onCompactApplied={compactAppliedHandler}
                onRename={renameQuoteHandler}
                onRemove={removeCompactHandler}
                custom={customProductData}
              />
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </QuoteAccordion>
    );
  }

  const quoteNames = quoteElements
    .map((q) => {
      return {
        value: q.quoteId.toString(),
        label: `${q.quoteName ?? "(Cargando...)"} (x${
          q.parts[0]?.form?.count ?? "1"
        })`,
      };
    })
    .concat(
      compactElements.map((c) => {
        return {
          value: (nextQuoteIndex.current + 1000 + c.compactId).toString(),
          label: `${c.compactName || "Compacto"} (x${c.form?.count ?? "1"})`,
        };
      })
    );

  return (
    <>
      <QuoteRequestModal
        data={printContent}
        opened={openPrintModal}
        onClose={closeRequestModalHandler}
        savingQuotes={userCtx.data.config.saveQuotes}
        error={requestQuoteMutation.isError}
        errorMessage={printError}
      />
      <QuoteRenameModal
        data={renameQuoteModal}
        maxOrder={quoteElements.length}
        opened={renameQuoteModal !== null}
        onConfirm={renameConfirmHandler}
        onClose={() => setRenameQuoteModal(null)}
      />

      <div id="scrollTopTarget" ref={topRef}></div>

      <Stack align="center">
        {(isLoading || isLoadingEdit) && <Loader size="xl" mt="xl" />}

        {showPaintSystemMessage && (
          <Alert
            mt="md"
            title="Importante"
            color="yellow"
            icon={<BiInfoCircle size={32} />}
            maw={800}
            withCloseButton
            closeButtonLabel="Cerrar mensaje"
            onClose={() => setShowPaintSystemMessage(false)}
          >
            Hemos modificado el sistema de ingreso de costos del{" "}
            <strong>aluminio por kilo</strong> para que sea más claro y fácil de
            usar. Desde ahora podrá ingresar el costo del aluminio por línea y
            por color directamente.
            <br />
            <br />
            Como consecuencia, los costos del aluminio ingresados previamente
            han vuelto a cero. Por favor diríjase a la sección{" "}
            <strong>Barras</strong> en la Configuración para ingresar los costos
            en el nuevo sistema.
            <br />
            <br />
            Este cambio sólo afecta a los usuarios que calculan los costos del
            aluminio por kilo.
            <br />
            <br />
            Disculpe las molestias, y gracias por confiar en nosotros mientras
            continuamos mejorando la herramienta.
          </Alert>
        )}

        {hasLoadError && (
          <Alert
            mt="md"
            title="Error al cargar presupuesto"
            color="red"
            icon={<BiError size={32} />}
            maw={900}
          >
            Uno o más artículos no se cargaron correctamente y por lo tanto se
            han quitado del presupuesto. Es posible que los diseños
            correspondientes hayan sido eliminados, o bien existe un problema en
            la conexión.
          </Alert>
        )}

        {<div className={styles.divQuotes}>{quotesContent}</div>}

        {compactElements.length > 0 && (
          <Title mt="xs" order={3}>
            Compactos
          </Title>
        )}

        {<div className={styles.divQuotes}>{compactsContent}</div>}

        {bonusItems.length > 0 && (
          <Title mt="xs" order={3}>
            Otros ítems
          </Title>
        )}

        {bonusItems.length > 0 && (
          <QuoteBonusTable
            items={bonusItems}
            onItemRemove={removeBonusItemHandler}
          />
        )}

        <div id="scrollBottomTarget" ref={bottomRef}></div>
        {!isLoading && !isLoadingEdit && designData.length > 0 && (
          <QuoteControlBox
            quoteCount={quoteElements.length}
            compactCount={compactElements.length}
            designs={designDropdownContent}
            quoteNames={quoteNames}
            brands={brandData}
            custom={customProductData}
            onAddQuote={addQuoteHandler}
            onAddCompact={addCompactHandler}
            onAddBonusItem={addBonusItemHandler}
            onPrecalculateCost={precalculateCostHandler}
            onFinalizeQuote={finalizeQuoteHandler}
            canAddQuotes={quoteElements.length < MAX_ARTICLE_LENGTH}
            advancedFeatures={userCtx.data.advFeatures}
            roundCosts={userCtx.data.config.roundCosts}
            maxQuoteCount={MAX_ARTICLE_LENGTH}
            editQuoteData={editMetadata}
            precalculatedCost={precalculatedCost}
            isPrecalculating={precalculateCostMutation.isLoading}
            isLoading={
              isLoading || isLoadingEdit || requestQuoteMutation.isLoading
            }
          />
        )}
      </Stack>

      <Affix position={{ bottom: 20, right: 30 }}>
        <Transition
          transition="slide-up"
          mounted={quoteElements.length > 0 && !openPrintModal}
        >
          {(transitionStyles) => (
            <Stack spacing="xs" style={transitionStyles}>
              <ActionIcon
                variant="filled"
                size="xl"
                radius="xl"
                onClick={() => scrollToTop({ alignment: "start" })}
              >
                <BiArrowToTop size={20} />
              </ActionIcon>
              <ActionIcon
                variant="filled"
                size="xl"
                radius="xl"
                onClick={() => scrollToBottom({ alignment: "start" })}
              >
                <BiArrowToBottom size={20} />
              </ActionIcon>
            </Stack>
          )}
        </Transition>
      </Affix>
    </>
  );
};

export default NewQuote;
